import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import store from '@/store'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlContent
    return api
  },

  baseUrl() {
    return FwEnvConfig.apiUrlContent
  },

  async getEvents(params) {
    const response = await this.base().get('/v1/manage/attendance/events', { params })
    return response.data
  },

  async getEvent(eventKey) {
    const response = await this.base().get(`/v1/manage/attendance/events/${eventKey}`)
    return response.data
  },

  async getEventAttendances(eventKey, params) {
    const response = await this.base().get(`/v1/manage/attendance/events/${eventKey}/attendances`, { params })
    return response.data
  },

  async getEventSessions(eventKey, params) {
    const response = await this.base().get(`/v1/manage/attendance/events/${eventKey}/sessions`, { params })
    return response.data
  },

  async getEventSession(eventKey, sessionkey) {
    const response = await this.base().get(`/v1/manage/attendance/events/${eventKey}/sessions/${sessionkey}`)
    return response.data
  },

  async getEventSessionAttendances(eventKey, sessionkey, params) {
    const response = await this.base().get(
      `/v1/manage/attendance/events/${eventKey}/sessions/${sessionkey}/attendances`,
      {
        params
      }
    )
    return response.data
  },

  createEventSubscription(eventKey, callbackFunc) {
    const cls = {
      authUserKey: store.getters.getUser.key,
      key: eventKey,
      callbackFunc: callbackFunc,

      subscribe() {
        const data = {
          application: 'content',
          code: 'subscribe_event_attendances',
          event_key: cls.key
        }

        store.commit('sendWSMessage', data)
        console.debug('Event subscribe', cls.key)
      },

      unsubscribe() {
        const data = {
          application: 'content',
          code: 'unsubscribe_event_attendances',
          event_key: cls.key
        }

        store.commit('sendWSMessage', data)
        console.debug('Event unsubscribe', cls.key)
      },

      reconnect() {
        cls.subscribe()
      },

      WSMessages(message) {
        console.debug('Received WSMessages', message)
        if (message.new_attendance) cls.callbackFunc(message.new_attendance[0])
      },

      destroy() {
        cls.unsubscribe()
        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: `content-${cls.key}` })
        store.commit('unsubscribeWS', { code: 'content', name: `content-${cls.key}` })
      }
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: `content-${cls.key}`, callback: cls.reconnect })
    store.commit('subscribeWS', { code: 'content', name: `content-${cls.key}`, callback: cls.WSMessages })
    cls.subscribe()
    return cls
  }
}
