<template>
  <div
    class="relative rounded-lg px-3 py-2.5 hover:bg-white group w-full flex flex-col text-left hover:shadow"
    :class="{
      'bg-gray-300 bg-opacity-20 shadow-none': !active,
      'bg-white shadow-sm': active
    }"
  >
    <div class="flex-1 flex items-center gap-2">
      <fw-icon-calendar-event class="w-4 h-4" :class="{ 'text-primary': active }" />
      <v-clamp autoresize :max-lines="1" class="font-semibold" :class="{ 'text-primary': active }">
        {{ item.title }}
      </v-clamp>

      <fw-dot
        inline
        :color="hasNewActivity ? 'primary' : 'light'"
        :ping="hasNewActivity"
        :class="{ 'opacity-60': !hasNewActivity }"
        class="ml-auto"
      />
    </div>

    <div v-if="item.url" class="text-xs text-gray-500">
      {{ item.url }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    item: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean,
      default: false
    },

    hasNewActivity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },

  computed: {
    lang() {
      return this.$store.state.language
    }
  }
}
</script>

<i18n lang="json">
  {
    "pt": {
      "spaceType": {
        "park": "Parque",
        "building": "Edifício",
        "other": "Outro"
      }
    },
    "en": {
      "spaceType": {
        "park": "Park",
        "building": "Building",
        "other": "Other"
      }
    }
  }
  </i18n>
