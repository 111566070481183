<template>
  <fw-layout
    wide
    paddingless
    :loading="loadingInitial"
    management
    mobile-ready
    :title="event?.title"
    subtitle="Registo de marcações do evento"
    loading-title="Eventos"
    main-content-scrollable
    main-listbar-width="w-[23rem]"
    main-sidebar-width="w-auto"
  >
    <template #main-sidebar>
      <SidebarList
        data-title="Eventos"
        data-key="events"
        :api-call-function="getEvents"
        :starts-open="false"
        :show-filters="false"
        collapsable
        width="w-[23rem]"
        :scroll-to-el-id="eventKey ? `sidebar_event_${eventKey}` : null"
      >
        <template #records="{ items }">
          <router-link
            v-for="evt in items"
            :id="`sidebar_event_${evt.key}`"
            :key="evt.key"
            :to="{ name: 'manage-event', params: { key: evt.key } }"
          >
            <RecordManageEvent
              :item="evt"
              :active="evt.key == eventKey"
              :has-new-activity="
                Boolean(
                  hasNewActivity[evt.key] &&
                    Object.values(hasNewActivity[evt.key]).some(v => v === true) &&
                    evt.key != eventKey
                )
              "
            >
            </RecordManageEvent>
          </router-link>
        </template>
      </SidebarList>
    </template>

    <template #main-listbar>
      <div v-if="event" class="border-b p-4 flex flex-col gap-3">
        <div v-if="event?.title">
          <fw-heading size="h2">{{ event.title }}</fw-heading>
        </div>
        <div v-if="event.key" class="group">
          <fw-label>Código de gestão</fw-label>
          <div
            class="px-3 py-2 text-xl font-semibold text-gray-500 rounded-lg bg-gray-100 font-mono flex items-center gap-3 h-12"
          >
            <fw-icon-settings class="w-5 h-5 flex-shrink-0" />
            <span class="hidden group-hover:flex">{{ event.code }}</span>
            <span class="group-hover:hidden">******</span>
          </div>
        </div>
        <div v-if="event.description">
          <fw-label marginless>Descrição do evento</fw-label>
          <fw-panel-info clean>
            {{ event.description }}
          </fw-panel-info>
        </div>
        <div v-if="event?.url">
          <fw-label marginless>Página oficial</fw-label>
          <fw-panel-info clean>
            <a target="_blank" :href="event.url">{{ event.url }}</a>
          </fw-panel-info>
        </div>
      </div>

      <SidebarList
        :key="eventKey"
        :data-title="'Sessões'"
        data-key="sessions"
        width="w-auto"
        :api-call-function="getEventSessions"
        :show-filters="false"
        :show-searchbar="false"
        :collapsable="false"
        class="h-auto"
        :scroll-to-el-id="sessionKey ? `sidebar_session_${sessionKey}` : null"
      >
        <template #header>
          <router-link :to="{ name: 'manage-event', params: { key: eventKey } }" class="px-4 py-3">
            <div
              class="flex px-4 py-3 gap-3 relative w-full text-left group rounded-lg text-base items-center justify-between"
              :class="{
                'text-primary bg-white shadow-md': !sessionKey,
                'bg-gray-300 bg-opacity-20 text-gray-600 hover:text-gray-800': sessionKey
              }"
            >
              <div class="flex gap-3 items-center justify-between">
                <div>
                  <fw-icon-chart-box class="w-5 h-5 flex-shrink-0" />
                </div>
                <div class="font-bold">Todos os registos</div>
              </div>
            </div>
          </router-link>
        </template>
        <template #records="{ items }">
          <router-link
            v-for="item in items"
            :id="`sidebar_session_${item.key}`"
            :key="item.key"
            :to="{
              name: 'manage-event-session',
              params: { key: eventKey, sessionKey: item.key }
            }"
          >
            <RecordManageEventSession
              :item="item"
              :active="item.key == sessionKey"
              :last-activity="newActivity?.[eventKey]?.[item.key]"
              :has-new-activity="Boolean(hasNewActivity?.[eventKey]?.[item.key])"
            >
            </RecordManageEventSession>
          </router-link>
        </template>
      </SidebarList>
    </template>

    <template #main-content>
      <fw-panel :title="mainContentTitle" boxed="xs" class="p-3" :counter="attendances.length" sticky>
        <template #toolbar>
          <div class="flex items-center gap-2">
            <div class="items-center flex">
              <fw-button type="xlight" :loading="loading" rounded="left" size="sm" @click.native="refresh()">
                <template #icon>
                  <fw-icon-refresh class="w-4 h-4 text-gray-800" />
                </template>

                <template #default>
                  <span class="hidden sm:inline-block">Atualizar</span>
                </template>
              </fw-button>

              <fw-button type="xlight" rounded="right" size="sm" @click.native="toggleLiveMode">
                <template #icon>
                  <fw-dot
                    inline
                    :color="isLiveMode ? 'primary' : 'light'"
                    :ping="isLiveMode"
                    :class="{ 'opacity-60': !isLiveMode }"
                  />
                </template>

                <span :class="{ 'text-primary': isLiveMode }">Tempo real</span>
              </fw-button>
            </div>

            <span class="divider-vertical h-4" />

            <b-dropdown aria-role="list" position="is-bottom-left" class="paddingless">
              <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" label="" class="font-medium ">
                <fw-icon-download class="h-4 w-4" />
              </fw-button-dropdown>
              <b-dropdown-item custom aria-role="menuitem" class="w-56">
                <fw-button type="dropdown-item" :disabled="loadingExportToCsv" @click.native="exportToCSV">
                  <template #icon>
                    <div class="w-5 h-5">
                      <fw-icon-loading v-if="loadingExportToCsv" class="flex-shrink-0 w-4 h-4" />
                      <fw-icon-download v-else class="flex-shrink-0 w-5 h-5" />
                    </div>
                  </template>
                  Exportar para CSV
                </fw-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <fw-content-loader v-if="loading" />
        <template v-else>
          <fw-panel-info v-if="!attendances.length" clean class="p-2">
            Não existem registos de marcações para este evento.
          </fw-panel-info>
          <div
            v-for="(attendance, index) in attendances"
            :key="attendance.key"
            class="px-3 py-2 flex items-center gap-3"
            :class="{ 'border-b': index !== attendances.length - 1 }"
          >
            <div class="flex items-center justify-center rounded-full bg-gray-100 p-2">
              <fw-icon-arrow-down v-if="attendance.action === 'checkin'" class="h-4 w-4 flex-shrink-0 text-primary" />
              <fw-icon-arrow-up v-else class="h-4 w-4 flex-shrink-0 text-red-600" />
            </div>
            <div
              class="text-sm flex flex-col gap-1 leading-4 w-44"
              :class="{ 'text-center': !attendancesSessions[attendance?.event_session_key] }"
            >
              <span>{{ attendance.created_date | formatDateTimeSeconds }}</span>
              <span
                v-if="!sessionKey && attendancesSessions[attendance.event_session_key]"
                class="line-clamp-1 text-gray-500"
              >
                {{ attendancesSessions[attendance.event_session_key].title }}
              </span>
            </div>
            <div>
              <fw-person :person="users[attendance.user_key]" paddingless />
            </div>
          </div>
        </template>
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import SidebarList from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarList'
import RecordManageEvent from '@/components/records/manage/events/RecordManageEvent'
import RecordManageEventSession from '@/components/records/manage/events/RecordManageEventSession'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ServiceContentAttendances from '@/services/ServiceContentAttendances'

export default {
  components: {
    SidebarList,
    RecordManageEvent,
    RecordManageEventSession
  },
  data() {
    return {
      loadingInitial: true,
      loading: false,
      loadingExportToCsv: false,
      data: {},
      hasNewActivity: {},
      newActivity: {},
      savedFilters: {},
      users: {},
      attendances: [],
      attendancesSessions: {},
      event: null,
      session: null,
      activeView: 'list',
      isLiveMode: true
    }
  },

  computed: {
    api() {
      return ServiceContentAttendances
    },

    connectionId() {
      return this.$store.state.socket.connectionId
    },

    lang() {
      return this.$store.state.language
    },

    eventKey() {
      return this.$route.params.key
    },

    sessionKey() {
      return this.$route.params.sessionKey
    },

    user() {
      return this.$store.getters.getUser
    },

    mainContentTitle() {
      if (this.sessionKey && this.session) {
        return `Sessão ${this.session.title}`
      }

      if (this.eventKey && this.event) {
        return `Evento ${this.event.title}`
      }

      return ''
    }
  },

  watch: {
    eventKey() {
      this.unsubscribeWs()
      this.getDashboardData()
      this.subscribeWs()
    },

    sessionKey() {
      this.getDashboardData()
    },

    isLiveMode(newVal) {
      if (newVal && !this.wsSubscription) {
        this.subscribeWs()
      } else if (!newVal && this.wsSubscription) {
        this.unsubscribeWs()
      }
    }
  },

  mounted() {
    if (this.eventKey === 'default') {
      this.getEvents({ limit: 1 }).then(res => {
        if (res.events.length)
          return this.$router.push({
            name: 'manage-event',
            params: { key: res.events[0].key }
          })
      })
    } else {
      this.getDashboardData()
      this.subscribeWs()
    }
  },

  methods: {
    subscribeWs() {
      if (!this.wsSubscription) {
        this.wsSubscription = this.api.createEventSubscription(this.eventKey, this.updateDataFromWS)
        window.addEventListener('beforeunload', this.unsubscribeWs, {
          capture: true
        })
      }
    },

    unsubscribeWs() {
      if (this.wsSubscription) {
        this.wsSubscription.destroy()
        this.wsSubscription = null
      }
    },

    async getEvents(params) {
      return await this.api.getEvents(params)
    },

    async getEventSessions(params) {
      return await this.api.getEventSessions(this.eventKey, params)
    },

    async getDashboardData(params) {
      console.log('getDashboardData params :>> ', params)

      this.loading = true
      if (!this.sessionKey) {
        await utils.tryAndCatch(this, async () => {
          const response = await this.api.getEventAttendances(this.eventKey, params)
          console.log('response :>> ', response)
          this.event = response.event
          this.attendancesSessions = response.sessions
          this.attendances = response.attendances
          this.users = response.users
        })
      } else {
        await utils.tryAndCatch(this, async () => {
          const response = await this.api.getEventSessionAttendances(this.eventKey, this.sessionKey, params)
          console.log('response :>> ', response)
          this.event = response.event
          this.session = response.session
          this.attendances = response.attendances
          this.users = response.users
        })
      }

      if (this.loadingInitial && this.eventKey != 'default') this.loadingInitial = false

      this.loading = false
    },

    async exportToCSV(params) {
      this.loadingExportToCSV = true
      if (!this.sessionKey) {
        await utils.tryAndCatch(this, async () => {
          const data = await this.api.getEventAttendances(this.eventKey, {
            ...params,
            csv: true
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const now = Dates.now().format('YYYY-MM-DD_HH:mm:ss')
          utils.downloadFile(url, `${this.event.title}_${now}.csv`)
        })
      } else {
        await utils.tryAndCatch(this, async () => {
          const data = await this.api.getEventSessionAttendances(this.eventKey, this.sessionKey, {
            ...params,
            csv: true
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const now = Dates.now().format('YYYY-MM-DD_HH:mm:ss')
          utils.downloadFile(url, `${this.event.title}_${this.session.title}_${now}.csv`)
        })
      }

      this.loadingExportToCSV = false
    },

    // testeMe() {
    //   this.updateDataFromWS({
    //     event: {
    //       key: 'abc1232',
    //       key: '123456',
    //       title: 'Evento Lorem ipsum',
    //     },
    //     session: {
    //       key: 'a',
    //       event_key: 'abc1232',
    //       title: 'Workshop 2',
    //     },
    //     attendance: {
    //       key: new Date().getTime().toString(),
    //       event_session_key: 'a',
    //       user_key: 'HIII',
    //       action: 'checkout',
    //       created_date: new Date(),
    //     },
    //     user: {
    //       key: 'HIII',
    //       name: 'HIII',
    //       full_name: 'HIII',
    //       initials: 'UF',
    //       email: 'ucfwtest01@uc.pt',
    //       number: 1234500,
    //     },
    //   })
    // },

    newWsData({ event, session, attendance }) {
      if (!this.hasNewActivity.hasOwnProperty(event.key)) {
        this.$set(this.hasNewActivity, event.key, {})
      }
      if (!this.newActivity.hasOwnProperty(event.key)) {
        this.$set(this.newActivity, event.key, {})
      }

      this.$set(this.hasNewActivity[event.key], session.key, true)
      this.$set(this.newActivity[event.key], session.key, attendance)
      setTimeout(() => {
        this.$set(this.hasNewActivity[event.key], session.key, false)
      }, 5 * 60 * 1000)
    },

    updateDataFromWS(data) {
      console.log('updateData :>> ', data)
      const { event, session, user, attendance } = data

      this.newWsData({ event, session, attendance })

      if (event.key != this.eventKey) return
      if (this.sessionKey && session.key != this.sessionKey) return

      if (!this.attendances.find(s => s.key === attendance.key)) {
        this.attendances.unshift(attendance)
      }

      this.users[user.key] = data.user
    },

    refresh() {
      this.isLiveMode = false
      this.getDashboardData()
    },

    toggleLiveMode() {
      this.isLiveMode = !this.isLiveMode
    }
  }
}
</script>

<i18n lang="json">{
  "en": {},
  "pt": {}
}</i18n>
